window.ID = Date.now();

const CHAR_OFFSETS = {
  " ": [0, 0],
  "\n": [0, 0],
  "\t": [0, 0],
  "!": [1, 0],
  '"': [2, 0],
  "#": [3, 0],
  $: [4, 0],
  "%": [5, 0],
  "&": [6, 0],
  "'": [7, 0],
  "’": [7, 0],
  "(": [8, 0],
  ")": [9, 0],
  "*": [10, 0],
  "+": [11, 0],
  ",": [12, 0],
  "-": [13, 0],
  ".": [14, 0],
  "/": [15, 0],
  "0": [0, 1],
  "1": [1, 1],
  "2": [2, 1],
  "3": [3, 1],
  "4": [4, 1],
  "5": [5, 1],
  "6": [6, 1],
  "7": [7, 1],
  "8": [8, 1],
  "9": [9, 1],
  ":": [10, 1],
  ";": [11, 1],
  "<": [12, 1],
  "=": [13, 1],
  ">": [14, 1],
  "?": [15, 1],
  "@": [0, 2],
  A: [1, 2],
  B: [2, 2],
  C: [3, 2],
  D: [4, 2],
  E: [5, 2],
  F: [6, 2],
  G: [7, 2],
  H: [8, 2],
  I: [9, 2],
  J: [10, 2],
  K: [11, 2],
  L: [12, 2],
  M: [13, 2],
  N: [14, 2],
  O: [15, 2],
  P: [0, 3],
  Q: [1, 3],
  R: [2, 3],
  S: [3, 3],
  T: [4, 3],
  U: [5, 3],
  V: [6, 3],
  W: [7, 3],
  X: [8, 3],
  Y: [9, 3],
  Z: [10, 3],
  "[": [11, 3],
  "\\": [12, 3],
  "]": [13, 3],
  "^": [14, 3],
  _: [15, 3],
  "`": [0, 4],
  a: [1, 4],
  b: [2, 4],
  c: [3, 4],
  d: [4, 4],
  e: [5, 4],
  f: [6, 4],
  g: [7, 4],
  h: [8, 4],
  i: [9, 4],
  j: [10, 4],
  k: [11, 4],
  l: [12, 4],
  m: [13, 4],
  n: [14, 4],
  o: [15, 4],
  p: [0, 5],
  q: [1, 5],
  r: [2, 5],
  s: [3, 5],
  t: [4, 5],
  u: [5, 5],
  v: [6, 5],
  w: [7, 5],
  x: [8, 5],
  y: [9, 5],
  z: [10, 5],
  "{": [11, 5],
  "|": [12, 5],
  "}": [13, 5],
  "~": [14, 5],
  "🏠": [15, 5],
} as const;

// (r << 24) | (g << 16) | (b << 8) | a
// prettier-ignore
const DB32 = [
  0xFF000000, // 0
  0xFF222034, // 1
  0xFF45283c, // 2
  0xFF663931, // 3
  0xFF8f563b, // 4
  0xFFdf7126, // 5
  0xFFd9a066, // 6
  0xFFeec39a, // 7
  0xFFfbf236, // 8
  0xFF99e550, // 9
  0xFF6abe30, // 10
  0xFF37946e, // 11
  0xFF4b692f, // 12
  0xFF524b24, // 13
  0xFF323c39, // 14
  0xFF3f3f74, // 15
  0xFF306082, // 16
  0xFF5b6ee1, // 17
  0xFF639bff, // 18
  0xFF5fcde4, // 19
  0xFFcbdbfc, // 20
  0xFFffffff, // 21
  0xFF9badb7, // 22
  0xFF847e87, // 23
  0xFF696a6a, // 24
  0xFF595652, // 25
  0xFF76428a, // 26
  0xFFac3232, // 27
  0xFFd95763, // 28
  0xFFd77bba, // 29
  0xFF8f974a, // 30
  0xFF8a6f30, // 31
];

async function main() {
  const WINDOW_ID = window.ID;
  let PAUSED = false;
  interface RequestDebug {
    type: "debug";
    data: any;
  }
  interface RequestConnect {
    type: "connect";
  }
  type Request = RequestDebug | RequestConnect;
  const postMessage = (data: Request) => {
    (window as any).ReactNativeWebView?.postMessage(JSON.stringify(data));
  };
  const solana_connect = () => {
    postMessage({ type: "connect" });
  };
  const debug_log = (data: any) => {
    postMessage({ type: "debug", data });
  };
  window.onmessage = (e: MessageEvent) => {
    interface ResponseConnect {
      type: "connect";
      data: { publicKey: number[] };
    }
    type Response = ResponseConnect;
    debug_log(e.data);
    const res: Response = JSON.parse(e.data);
    switch (res.type) {
      case "connect": {
        try {
          if (!exports.PUBLIC_KEY) {
            debug_log("NO PUBKEY");
            PAUSED = false;
          }
          const mem = new Uint8Array(
            memory.buffer,
            exports.PUBLIC_KEY.value,
            32
          );
          for (let i = 0; i < res.data.publicKey.length; i++) {
            mem[i] = res.data.publicKey[i];
          }
          debug_log("WROTE PUBKEY");
          PAUSED = false;
        } catch (err) {
          debug_log(err.message);
        }
        break;
      }
    }
  };
  interface WasmExports {
    memory: WebAssembly.Memory;
    PUBLIC_KEY?: WebAssembly.Global;
    SPRITESHEET_BYTES?: WebAssembly.Global;
    SPRITESHEET_BYTE_LEN?: WebAssembly.Global;
    run(): void;
    _start?(): void;
  }
  enum InputState {
    Released = 0,
    JustPressed = 1,
    Pressed = 2,
    JustReleased = 3,
  }
  enum MouseButton {
    Left = 0,
    Right = 1,
  }
  enum GamepadButton {
    Up = 0,
    Down = 1,
    Left = 2,
    Right = 3,
    A = 4,
    B = 5,
  }
  enum Font {
    S = 0,
    M = 1,
    L = 2,
  }
  const params = new URLSearchParams(window.location.search.substring(1));
  const wasm_url = params.get("cart");
  const cover_url = wasm_url + ".png";
  let res = await fetch(wasm_url);
  const buf = await res.arrayBuffer();
  const obj = await WebAssembly.instantiate(buf, {
    solana: {
      connect() {
        PAUSED = true;
        debug_log("CONNECT");
        solana_connect();
      },
    },
    turbochonk16: {
      log(ptr_text: number) {
        const mem = new Uint8Array(memory.buffer);
        let text = "";
        for (let i = ptr_text, j = 0; mem[i] !== 0; i++, j++) {
          text += String.fromCharCode(mem[i]);
        }
        console.log(`[DEBUG] ${text}`);
        debug_log(text);
      },
      gamepad_button(button_id: GamepadButton): InputState {
        // TODO
        return InputState.Released;
      },
      mouse_button(button_id: MouseButton) {
        // prettier-ignore
        switch (button_id) {
          case MouseButton.Left:
            return MOUSE_STATE;
          // TODO: right mouse button
          case MouseButton.Right:
            return InputState.Released;
        }
      },
      mouse_position(mut_ptr_x: number, mut_ptr_y: number) {
        const mem = new DataView(memory.buffer);
        mem.setInt32(mut_ptr_x, MOUSE_X, true);
        mem.setInt32(mut_ptr_y, MOUSE_Y, true);
      },
      mouse_wheel(mut_ptr_x: number, mut_ptr_y: number) {
        const mem = new DataView(memory.buffer);
        mem.setInt32(mut_ptr_x, wheel_delta_x, true);
        mem.setInt32(mut_ptr_y, wheel_delta_y, true);
      },
      window_size(mut_ptr_w: number, mut_ptr_h: number) {
        const mem = new DataView(memory.buffer);
        mem.setInt32(mut_ptr_w, CANVAS_WIDTH, true);
        mem.setInt32(mut_ptr_h, CANVAS_HEIGHT, true);
      },
      clear(color: number) {
        const c = DB32[color];
        if (bg_buf[0] !== c) bg_buf.fill(c);
        ctx.putImageData(bg, 0, 0);
      },
      // prettier-ignore
      draw_text(font: Font, x: number, y: number, color: number, ptr_text: number) {
        const mem = new Uint8Array(memory.buffer);
        text_ctx.save();
        text_ctx.clearRect(0, 0, text_ctx.canvas.width, text_ctx.canvas.height);
        for (let i = ptr_text, j = 0, n = 0; mem[i] !== 0; i++, j++) {
          const char = String.fromCharCode(mem[i]);
          if (char === '\n') {
            n++;
            j = -1;
            continue;
          }
          const [sx, sy] = CHAR_OFFSETS[char];
          switch (font) {
            case Font.S: text_ctx.drawImage(font_5x5_bmp, sx * 5, sy * 5, 5, 5, x + (j*5), y + (n*5), 5, 5); break;
            case Font.M: text_ctx.drawImage(font_5x8_bmp, sx * 5, sy * 8, 5, 8, x + (j*5), y + (n*8), 5, 8); break;
            case Font.L: text_ctx.drawImage(font_8x8_bmp, sx * 8, sy * 8, 8, 8, x + (j*8), y + (n*8), 8, 8); break;
          }
        }
        const argb = DB32[color];
        let a = (argb & 0xFF000000) >> 24;
        let r = (argb & 0x00FF0000) >> 16;
        let g = (argb & 0x0000FF00) >> 8;
        let b = argb & 0x000000FF;
        const hex = '#' + r.toString(16)+g.toString(16)+b.toString(16);
        text_ctx.fillStyle = hex;
        text_ctx.globalCompositeOperation = "source-in";
        text_ctx.fillRect(0, 0, text_ctx.canvas.width, text_ctx.canvas.height);
        text_ctx.restore();
        ctx.drawImage(text_ctx.canvas, 0, 0);
      },
      // prettier-ignore
      draw_rect(x: number, y: number, w: number, h: number, color: number) {
        if (!w || !h) return;
        const rect = ctx.createImageData(w, h);
        new Uint32Array(rect.data.buffer).fill(DB32[color]);
        ctx.putImageData(rect, x, y);
      },
      // prettier-ignore
      draw_sprite(dx: number, dy: number, dw: number, dh: number, sx: number, sy: number, sw: number, sh: number) {
        if (!spritesheet_bmp) return;
        ctx.drawImage(spritesheet_bmp, sx, sy, sw, sh, dx, dy, dw, dh);
      },
    },
    // NOTE: Grain compat
    wasi_snapshot_preview1: {
      fd_write(_a, _b, _c, _d) {
        return 0;
      },
    },
  });

  const exports = obj.instance.exports as any as WasmExports;
  const memory = exports.memory;
  const mem = new DataView(memory.buffer);

  let spritesheet_bmp: ImageBitmap | null = null;
  if (exports.SPRITESHEET_BYTES && exports.SPRITESHEET_BYTE_LEN) {
    const spritesheet_bytes = new Uint8Array(
      memory.buffer,
      exports.SPRITESHEET_BYTES.value,
      Math.min(
        mem.getInt32(exports.SPRITESHEET_BYTE_LEN.value, true),
        256 * 640 * 4
      )
    );
    const spritesheet_blob = new Blob([spritesheet_bytes], {
      type: "image/png",
    });
    spritesheet_bmp = await createImageBitmap(spritesheet_blob);
  }

  const makeFontBitmap = async (src: string) => {
    const res = await fetch(src);
    const blob = await res.blob();
    return createImageBitmap(blob);
  };
  const [font_5x5_bmp, font_5x8_bmp, font_8x8_bmp] = await Promise.all([
    makeFontBitmap("./5x5.png"),
    makeFontBitmap("./5x8.png"),
    makeFontBitmap("./8x8.png"),
  ]);

  const run = exports.run;

  const text_canvas: HTMLCanvasElement = document.createElement("canvas");
  text_canvas.width = window.innerWidth;
  text_canvas.height = window.innerHeight;
  const text_ctx = text_canvas.getContext("2d");

  const CANVAS_WIDTH = 144;
  const CANVAS_HEIGHT = 256;
  let CANVAS_SCALE = 1;

  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  canvas.style.width = "100vw";
  canvas.style.height = "100vh";
  const ctx = canvas.getContext("2d");
  const onresize = () => {
    const scale_w = window.innerWidth / CANVAS_WIDTH;
    const scale_h = window.innerHeight / CANVAS_HEIGHT;
    const max_scale = Math.max(scale_w, scale_h);
    const min_scale = Math.min(scale_w, scale_h);
    if (window.innerWidth < max_scale * CANVAS_WIDTH) {
      CANVAS_SCALE = min_scale;
    } else if (window.innerHeight < max_scale * CANVAS_HEIGHT) {
      CANVAS_SCALE = min_scale;
    } else {
      CANVAS_SCALE = max_scale;
    }
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    text_canvas.width = window.innerWidth;
    text_canvas.height = window.innerHeight;
    ctx.imageSmoothingEnabled = false;
  };
  onresize();
  window.onresize = onresize;

  // Background (clear)
  const bg = ctx.createImageData(CANVAS_WIDTH, CANVAS_HEIGHT);
  const bg_buf = new Uint32Array(bg.data.buffer);

  // Mouse wheel
  let wheel_delta_x = 0;
  let wheel_delta_y = 0;
  document.onwheel = (e) => {
    wheel_delta_x = e.deltaX * 0.25;
    wheel_delta_y = -e.deltaY * 0.25;
  };

  // Mouse
  let MOUSE_STATE = InputState.Released;
  let MOUSE_X = 0;
  let MOUSE_Y = 0;
  let IS_TOUCH_ENABLED =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  const setMousePosition = (clientX: number, clientY: number) => {
    const rect = canvas.getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    const canvasX = (x * canvas.width) / (canvas.width * CANVAS_SCALE);
    const canvasY = (y * canvas.height) / (canvas.height * CANVAS_SCALE);
    MOUSE_X = canvasX;
    MOUSE_Y = canvasY;
  };
  if (IS_TOUCH_ENABLED) {
    document.ontouchstart = (e) => {
      MOUSE_STATE = InputState.JustPressed;
      const { clientX, clientY } = e.touches[0];
      setMousePosition(clientX, clientY);
    };
    document.ontouchmove = (e) => {
      MOUSE_STATE = InputState.Pressed;
      const { clientX, clientY } = e.touches[0];
      setMousePosition(clientX, clientY);
    };
    document.ontouchend = (e) => {
      MOUSE_STATE = InputState.JustReleased;
      const { clientX, clientY } = e.touches[0];
      setMousePosition(clientX, clientY);
    };
  } else {
    document.onmousedown = (e) => {
      MOUSE_STATE = InputState.JustPressed;
      const { clientX, clientY } = e;
      setMousePosition(clientX, clientY);
    };
    document.onmousemove = (e) => {
      if (MOUSE_STATE === InputState.JustPressed) {
        MOUSE_STATE = InputState.Pressed;
      }
      const { clientX, clientY } = e;
      setMousePosition(clientX, clientY);
    };
    document.onmouseup = (e) => {
      MOUSE_STATE = InputState.JustReleased;
      const { clientX, clientY } = e;
      setMousePosition(clientX, clientY);
    };
  }

  document.body.append(canvas);
  if (exports._start) exports._start();

  // const cover_bmp = await createImageBitmap(
  //   await (await fetch(cover_url)).blob()
  // );
  // ctx.drawImage(cover_bmp, 0, 0, 144, 144);

  debug_log(window.location);

  setTimeout(() => {
    requestAnimationFrame(function loop() {
      try {
        if (!PAUSED) {
          run();
          // flip scaled canvas onto itself
          ctx.drawImage(
            canvas,
            0,
            0,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            0,
            0,
            CANVAS_WIDTH * CANVAS_SCALE,
            CANVAS_HEIGHT * CANVAS_SCALE
          );
          wheel_delta_x = 0;
          wheel_delta_y = 0;
          // if (did_mouse_up) {
          //   MOUSE_STATE = InputState.JustReleased;
          //   did_mouse_up = false;
          // } else {

          if (MOUSE_STATE === InputState.JustPressed)
            MOUSE_STATE = InputState.Pressed;

          if (MOUSE_STATE === InputState.JustReleased)
            MOUSE_STATE = InputState.Released;
          // }
        }
        if (WINDOW_ID === window.ID) {
          requestAnimationFrame(loop);
        }
      } catch (err) {
        debug_log(err.message);
      }
    });
  }, 200);
}

main().catch((err) => {
  console.error(err);
  document.body.innerText = "ERROR: " + err.message;
});
